import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import ApiManager from "../utils/ApiManager";
import Spinner from "./Spinner";

interface PropTypes {
  isVisible: boolean;
  onClose: () => void;
  inWallet: any;
  driver: string | undefined;
}

const PayoutModal = ({ isVisible, onClose, inWallet, driver }: PropTypes) => {
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (!amount) {
      setError("Please enter a valid amount.");
      setLoading(false);
      return;
    }
    const body = {
      amount,
      driver,
      description: "",
    };
    if (parseFloat(amount) <= parseFloat(inWallet)) {
      const res = await new ApiManager().payout(body);
      if (res?.error) {
        setError(res?.error);
      } else setError("");
    } else {
      setError("Invalid amount.");
    }
    setLoading(false);
  };

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white w-full flex px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="w-full h-full">
                  <div className="w-full border-b border-b-black h-1/4 items-center justify-start px-4 py-2 flex">
                    <p className="text-xl font-semibold">Payout</p>
                  </div>
                </div>
              </div>
              <div className="px-10">
                {parseFloat(inWallet) >= 0 && (
                  <p className="mb-2 font-medium text-gray-900 text-sm">
                    Currently in wallet : ${parseFloat(inWallet)?.toFixed(2)}
                  </p>
                )}
                <label
                  htmlFor="amount"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Amount
                </label>
                <input
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  id="amount"
                  className="focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                {error && <p className="text-sm mt-2 text-red-500">{error}</p>}
              </div>
              <div className="w-full px-10 mb-4 flex justify-end mt-10">
                <button
                  onClick={onClose}
                  className="w-32 h-10 rounded-lg bg-gray-200"
                >
                  Close
                </button>
                <button
                  disabled={loading}
                  onClick={handleSubmit}
                  className="w-32 flex items-center justify-center h-10 rounded-lg bg-primary text-white ml-4"
                >
                  {loading && <Spinner />}
                  Pay
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PayoutModal;
