import React from "react";
import { twMerge } from "tailwind-merge";

type ChildrenType = { children?: React.ReactNode };
type THType = ChildrenType & { className?: string; onClick?: () => void };

const DataTable = ({ children }: ChildrenType) => {
  return (
    <div className="relative overflow-y-hidden w-full h-full overflow-x-auto">
      <table className="w-full text-gray-900 font-normal text-sm text-left">
        {children}
      </table>
    </div>
  );
};

export const THead = ({ children }: ChildrenType) => {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
      {children}
    </thead>
  );
};

export const TBody = ({ children }: ChildrenType) => {
  return <tbody>{children}</tbody>;
};

export const TH = ({ children, className, onClick }: THType) => {
  return (
    <th
      onClick={onClick}
      scope="row"
      className={twMerge("px-6 py-4", className)}
    >
      {children}
    </th>
  );
};

export const TR = ({ children }: ChildrenType) => {
  return <tr className="border-b flex-shrink-0">{children}</tr>;
};

export default DataTable;
