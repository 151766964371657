import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

interface PropTypes extends ComponentProps<"input"> {
  label?: string;
  error?: string;
  isInvalid?: boolean;
  containerClassName?: string;
  rightContent?: React.ReactNode;
}

const Input = ({
  label,
  error,
  className,
  isInvalid = false,
  containerClassName,
  rightContent,
  ...rest
}: PropTypes) => {
  return (
    <div className={twMerge(containerClassName)}>
      {label ? (
        <div className="label flex-shrink-0">
          <span className="label-text">{label}</span>
        </div>
      ) : null}

      <div className="join w-full">
        <input
          {...rest}
          className={twMerge(
            "input join-item w-full input-primary focus:border-none ",
            className
          )}
        />
        {rightContent ? <div className="join-item">{rightContent} </div> : null}
      </div>
      {isInvalid && error ? (
        <div className="label">
          <span className="label-text-alt text-red-600">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default Input;
