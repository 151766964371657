import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";

const DashboardLayout = () => {
  return (
    <div className="w-full h-full">
      <TopBar />
      <div className="flex h-full w-full">
        <Sidebar />
        <div
          style={{ minHeight: "calc(100vh - 80px)" }}
          className="w-80% px-6 py-2 relative  font-inter bg-gray-100 h-full"
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
