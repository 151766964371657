import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import ApiManager from "../../utils/ApiManager";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  driverId: string | undefined;
  onApproveOrRejectSuccess: () => void;
}

const ResubmittedDocuments = ({
  isVisible,
  onHide,
  driverId,
  onApproveOrRejectSuccess,
}: PropTypes) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [isDocumentsError, setIsDocumentsError] = useState(false);
  // const { data, isError: isDocumentsError } =
  //   useGetResubmitedDocumentsQuery(driverId);
  // const [approveOrRejectResubmittedDocuments, { isLoading, isError }] =
  //   useApproveOrRejectResubmittedDocumentsMutation();

  const handleGetResubmittedDocuments = async () => {
    const res: any = await new ApiManager().getResubmittedDocuments(
      driverId || ""
    );
    if (res?.success) {
      setData(res.data);
      setIsDocumentsError(false);
    } else {
      setIsDocumentsError(true);
    }
  };

  const handleApproveOrReject = async (isApproved: boolean) => {
    setIsLoading(true);
    const res = await new ApiManager().approveOrRejectResubmittedDocuments(
      driverId || "",
      isApproved
    );
    if (res?.success) {
      toast(`Documents ${isApproved ? "Approved" : "Rejected"} Successfully`, {
        position: "top-right",
        type: "success",
      });
    } else {
      toast("Something went wrong", { position: "top-right", type: "error" });
    }
    setIsLoading(false);
    onApproveOrRejectSuccess();
    onHide();
  };

  const documents = useMemo(() => {
    if (isDocumentsError) return [];
    const newDrivingLicenseFile = {
      name: "New Driving License File",
      file: data?.newDrivingLicenseFile,
    };
    const newVehicleInsuranceFile = {
      name: "New Vehicle Insurance File",
      file: data?.newVehicleInsuranceFile,
    };
    const newVehicleRegistrationFile = {
      name: "New Vehicle Registration File",
      file: data?.newVehicleRegistrationFile,
    };

    const docs = [
      newDrivingLicenseFile,
      newVehicleInsuranceFile,
      newVehicleRegistrationFile,
    ];

    const nonEmptyDocuments = docs.filter((item) => item.file !== undefined);

    return nonEmptyDocuments;
  }, [data, isDocumentsError]);

  useEffect(() => {
    handleGetResubmittedDocuments();
  }, [driverId]);

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onHide}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              onClick={onHide}
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block p-4 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="w-full flex items-center justify-end">
                <AiOutlineClose className="cursor-pointer" onClick={onHide} />
              </div>
              <div className="w-full flex items-center justify-center mb-10">
                <p className="text-xl font-bold ">Resubmitted Documents</p>
              </div>
              <div className="w-full grid grid-cols-2  h-full">
                {documents.map(
                  (doc) =>
                    doc.file !== "" && (
                      <a target="_blank" href={doc.file}>
                        <button
                          style={{
                            background:
                              "linear-gradient(357.95deg, #339500 1.23%, #77CB02 97.91%)",
                          }}
                          className="px-4 mb-4 py-2 text-white rounded-lg"
                        >
                          {doc.name}
                        </button>
                      </a>
                    )
                )}
              </div>
              {documents.length > 0 && (
                <div className="w-full flex gap-4 items-center justify-center mt-10">
                  <button
                    disabled={isLoading}
                    onClick={() => handleApproveOrReject(true)}
                    className="bg-primary text-white rounded px-4 py-2"
                  >
                    Approve
                  </button>
                  <button
                    disabled={isLoading}
                    onClick={() => handleApproveOrReject(false)}
                    className="bg-red-600 text-white rounded px-4 py-2"
                  >
                    Reject
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ResubmittedDocuments;
