// @ts-nocheck

import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import ApiManager from "../utils/ApiManager";
import DataTable, { TBody, TH, THead, TR } from "./DataTable";
import Pagination from "./Pagination";

const Withdraw = () => {
  const [services, setServices] = useState<[]>([]);
  const [status, setstatus] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const form = useRef<HTMLFormElement>(null);

  const getAllServices = async () => {
    setLoading(true);
    let res = await new ApiManager().getwithdraw();

    if (res?.success) {
      setServices(res?.payload?.data.transactions);
      setTotalPage(res?.payload?.data?.totalPages);
      //  console.log("total page",totalPage)
    }
    setLoading(false);
  };

  const filterServices = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const res: any = await new ApiManager().getwithdraw(status);
    if (res?.success) {
      {
        console.log("type", status);
      }

      setServices(res?.payload?.data.transactions);
      setTotalPage(res?.payload?.data?.totalPages);
    } else {
      alert(res?.message);
    }
    setLoading(false);
  };

  const handlePageClick = async (e: { selected: number }) => {
    setLoading(true);
    const page = e.selected + 1;
    const res = await new ApiManager().getwithdraw(status, page);
    if (res?.success) {
      setServices(res?.payload?.data?.transactions);
    }
    setLoading(false);
  };

  const resetFilters = () => {
    form.current?.reset();

    setstatus("");
    getAllServices();
  };

  useEffect(() => {
    getAllServices();
  }, []);

  const changeWithdrawlStatus = async (status: string, id: string) => {
    const res = await new ApiManager().changeWithdrawlStatus(status, id);
    if (res?.success) {
      const newTransaction = res?.payload?.data?.transaction;
      let newArr = [...services];
      console.log(newArr);
      let itemToBeUpdatedIndx = newArr.findIndex((item) => item?._id === id);
      newArr[itemToBeUpdatedIndx].withdrawalStatus =
        newTransaction.withdrawalStatus;

      setServices(newArr);
    }
  };

  return (
    <div className="w-full h-full">
      {/* <Sidebar/> */}
      <Helmet>
        <title>Withdrawal Dashboard</title>
      </Helmet>

      {/* <div className='w-full h-20 flex justify-between items-center'> */}
      <p className="text-xl mb-4 font-semibold ">Withdraw History</p>
      {/* </div> */}

      <div className="w-full h-20 flex justify-between items-center">
        <form
          ref={form}
          onSubmit={filterServices}
          className="w-full flex items-center justify-end mb-4"
        >
          <select
            onChange={(e) => {
              setstatus(e.target.value);
            }}
            defaultValue=""
            // onChange={e => setPast(e.target.value)}
            className="w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4"
          >
            <option value="" disabled hidden>
              Status
            </option>
            <option value="accepted">accepted</option>
            <option value="rejected">rejected</option>
          </select>
          {/* <select
                            defaultValue=""
                            onChange={e => setPast(e.target.value)}
                            className='w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4'
                        >
                            <option value="" disabled hidden>Time</option>
                            <option value="">All</option>
                            <option value="1">Today</option>
                            <option value="7" >Last Week</option>
                            <option value="30">Last Month</option>
                        </select> */}

          {/* <select
								defaultValue=""
								onChange={e => setSort(e.target.value)}
								className='w-32 outline-none bg-white rounded-lg h-10 pl-4 pr-2 ml-4 '
							>
								<option value="" disabled hidden>Sort</option>
								<option value="desc">Descending</option>
								<option value="asc">Ascending</option>
							</select> */}
          {/* <div className='flex items-center ml-4'>
                            <label htmlFor='from_date'>From: </label>
                            <input onChange={e => setFromDate(e.target.value)} id='from_date' className='w-44 rounded h-10 bg-white ml-2 px-2' type='date' />
                        </div>
                        <div className='flex items-center ml-4'>
                            <label htmlFor='to_date'>To: </label>
                            <input onChange={e => setToDate(e.target.value)} id='to_date' className='w-44 rounded h-10 bg-white ml-2 px-2' type='date' />
                        </div> */}
          <button
            type="button"
            onClick={resetFilters}
            className="w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary"
          >
            Clear
          </button>

          <button
            type="submit"
            className="w-28 ml-4 h-10 bg-primary rounded-lg  text-white"
          >
            Apply
          </button>
          <button
            type="button"
            onClick={() => window.location.reload(true)}
            className="w-32 bg-transparent border border-primary text-primary h-10 ml-4 rounded"
          >
            Refresh
          </button>
        </form>
      </div>

      <div className="h-4/5 overflow-x-auto flex flex-col justify-between w-full bg-white rounded-lg">
        {loading === true ? (
          <div className="w-full h-full flex items-center justify-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            {services && services.length === 0 ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Withdrawl requests found
                </p>
              </div>
            ) : (
              <>
                <DataTable className="w-full h-4/5 overflow-x-auto">
                  <THead>
                    <TR>
                      <TH>Drivers</TH>
                      <TH>Amount</TH>
                      <TH>Type</TH>
                      <TH>Status</TH>
                      <TH>Action</TH>
                    </TR>
                  </THead>
                  <TBody>
                    {services?.map((user) => (
                      <TR className="w-full 2xl:justify-between grid grid-cols-5 py-5 items-center border-b border-gray-200 mb-2">
                        <Link to={`/dashboard/drivers/${user?.driver?._id}`}>
                          <TH>
                            <p className="truncate text-md font-semibold">
                              {user?.driver?.fullName}
                            </p>
                          </TH>
                        </Link>
                        <TH className="relative group">
                          <p>$ {user?.amount?.toFixed(2)}</p>
                        </TH>
                        <TH>{user?.type}</TH>
                        <TH>{user?.withdrawalStatus}</TH>
                        <TH className="flex mt-5 w-40">
                          {user?.withdrawalStatus === "pending" ? (
                            <>
                              <button
                                onClick={() =>
                                  changeWithdrawlStatus("rejected", user?._id)
                                }
                                className="px-4 mr-2 rounded h-10 bg-red-500 text-white"
                              >
                                Reject
                              </button>
                              <button
                                onClick={() =>
                                  changeWithdrawlStatus("accepted", user?._id)
                                }
                                className="px-4 rounded h-10 bg-primary mr-4 text-white"
                              >
                                Accept
                              </button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </TH>
                      </TR>
                    ))}
                  </TBody>
                </DataTable>
              </>
            )}
          </>
        )}
      </div>

      {/* <div className='h-4/5 px-14 overflow-auto  flex flex-col justify-between w-full bg-white rounded-lg'>
                    {loading === false && services?.length === 0 ? (
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <img className='w-1/5' src={empty} />
                            <p className='mt-10 text-2xl text-gray-500 font-inter'>No Services Found</p>
                        </div>
                    ) : (

                        <table className='table-auto h-4/5'>
                            {loading && loading === true ? (
                                <div className="w-full h-full flex items-center justify-center">
                                    <div className="loader"></div>
                                </div>
                            ) : (

                                <>
                                        <tr className="h-20" >
                                            <td  className=' text-gray-400'>Drivers</td>
                                            <td className='text-gray-400'>Amount</td>
                                            <td  className='text-gray-400'>Type</td>
                                            <td  className='text-gray-400'>Status</td>
                                            <td  className="text-gray-400" >Actions</td>
                                        </tr>
                                    
                                        {services?.map(order => (
                                            <tr  className='border-b border-gray-200 items-center h-20 '>
                                                <Link to={`/dashboard/drivers/${order.driver._id}`}>
                                                    <td className="cursor-pointer " >
                                                        {order?.driver.fullName}
                                                    </td>
                                                </Link>
                                                <td>
                                                    {order?.amount}
                                                </td>
                                                <td >
                                                    {order?.type}
                                                </td>

                                                <td >
                                                    {order?.withdrawalStatus}
                                                </td>

                                                <td className="flex mt-5">
                                                    {
                                                        order?.withdrawalStatus === "pending" ?
                                                            <>
                                                                <button onClick={() => changeWithdrawlStatus("rejected", order?._id)} className="px-4 mr-2 rounded h-10 bg-red-500 text-white" >Reject</button>
                                                                <button onClick={() => changeWithdrawlStatus("accepted", order?._id)} className="px-4 rounded h-10 bg-primary mr-4 text-white" >Accept</button>
                                                            </>

                                                            : "N/A" 
                                                    }

                                                </td>
                                            </tr>
                                        ))
                                        }


                                </>
                            )}
                        </table>
                    )}
                </div> */}
      <div className="mt-6">
        <Pagination
          page={totalPage}
          handlePageClick={(e) => handlePageClick(e)}
        />
      </div>
    </div>
  );
};

export default Withdraw;
