//@ts-nocheck

// Import the functions you need from the SDKs you need
import firebase from "firebase";
import "firebase/storage";

import { toast } from "react-toastify";
import ApiManager from "./utils/ApiManager";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOEv9J9J-13PxRqN6bkhIfri2QkOWZdtM",
  authDomain: "swyvery.firebaseapp.com",
  projectId: "swyvery",
  storageBucket: "swyvery.appspot.com",
  messagingSenderId: "92674228533",
  appId: "1:92674228533:web:64780c985fc35fda768c7c",
  measurementId: "G-638VMTTBBJ",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

// firebase.messaging().usePublicVapidKey("")

const messaging = firebase.messaging();

export const storage = firebase.storage();

export const getToken = () =>
  messaging
    .getToken({
      vapidKey:
        "BLwe3ejWxaUV3oN63ING_wjzYkfnWBZjJ4_mUK206S4JucYf42C_Eun9ic2p3PuiZeE0X0nC4qSftRo2QcHRPvo",
    })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        console.log(currentToken);
        return currentToken;
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });

messaging.onMessage(async (payload) => {
  console.log(
    window.location.href === "http://localhost:3000/dashboard/orders" &&
      (await new ApiManager().getOrders())
  );
  toast(payload.notification.title + "," + payload.notification.body, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  // alert(payload.notification.body)
  // ...
});

export default firebase;
