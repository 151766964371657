import { useState, useEffect, useCallback } from "react";
import ApiManager from "../utils/ApiManager";

type VehicleType = {
  label: string;
  value: string;
};

const useVehicleTypes = () => {
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([
    { label: "Sedan Coupe", value: "sedan_coupe" },
    {
      value: "suv",
      label: "Suv",
    },
    { value: "pickup_truck", label: "Pickup Truck" },
    { value: "pickupTruck", label: "Pickup Truck" },
    {
      value: "van",
      label: "Van",
    },
    {
      value: "car",
      label: "Car",
    },
    {
      label: "Other",
      value: "other",
    },
  ]);

  const fetchVehicleTypes = async () => {
    try {
      const res = await new ApiManager().getVehicleDetails();
      if (res.success) {
        setVehicleTypes((prev) => [...prev, ...res.data?.types]);
      }
    } catch (error) {
      console.error("Failed to fetch vehicle types:", error);
    }
  };

  // Function to map vehicleType to its label
  const getVehicleLabel = useCallback(
    (type: string) => {
      return (
        vehicleTypes.find(
          (item) => item.value?.toLowerCase() === type?.toLowerCase()
        )?.label ?? "N/A"
      );
    },
    [vehicleTypes]
  );

  useEffect(() => {
    fetchVehicleTypes();
  }, []);

  return {
    vehicleTypes,
    getVehicleLabel,
  };
};

export default useVehicleTypes;
