import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <AiOutlineArrowLeft
      onClick={goBack}
      className="text-primary cursor-pointer text-3xl mb-10"
    />
  );
};

export default BackButton;
