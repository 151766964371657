import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ApiManager from "../utils/ApiManager";
import Button from "./Button";
import Checkbox from "./Checkbox";
import { FormValues } from "./EditPromoCode";
import Input from "./Input";
import Modal from "./Modal";
import Select from "./Select";
import Textarea from "./Textarea";
import Toggle from "./Toggle";
import { isPromoCodeDescriptionValid } from "../utils/helpers";
import { serviceTypeMap } from "./ServicesDashboard";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

export const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

export const serviceTypes = [
  { label: "Package Pickup", value: "package_pickup_meta" },
  { label: "Curbside Pickup", value: "curbside_pickup_meta" },
  { label: "Instore Pickup", value: "instore_pickup_meta" },
];

export const discountOptions = [
  {
    label: "Flat Discount",
    value: "flat",
  },
  {
    label: "Percentage Discount",
    value: "percentage",
  },
];

const AddPromoCodeModal = ({ isVisible, onHide, onSuccess }: PropTypes) => {
  const [adminAddedDescription, setAdminAddedDescription] = useState(false);

  const handleHide = () => {
    setAdminAddedDescription(false);
    onHide();
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      promoCode: "",
      serviceType: [],
      discountType: "",
      discountValue: "",
      description: "",
      startDate: "",
      expiryDate: null,
      maxUsers: "",
      maxBudget: "",
      isNotifyUser: "false",
      isPromoCodeActive: true,
      isSingleUse: "false",
    },
    validationSchema: Yup.object().shape({
      promoCode: Yup.string()
        .trim()
        .matches(/^\S*$/, "Promo code cannot contain spaces")
        .required("This field is required"),
      serviceType: Yup.array()
        .min(1, "At least one service is required")
        .required("This field is required"),
      discountType: Yup.string().required("This field is required"),
      discountValue: Yup.number().positive().required("This field is required"),
      description: Yup.string()
        .trim()
        .required("This field is required")
        .test(
          "description-match",
          "Description doesn't match selected options",
          function (value) {
            const { discountType, discountValue, serviceType } = this.parent;
            const validation = isPromoCodeDescriptionValid(
              value,
              discountType,
              discountValue,
              serviceType,
              serviceTypeMap
            );

            if (!validation.isValid) {
              return this.createError({
                message: validation.error,
              });
            }

            return true;
          }
        ),
      startDate: Yup.date()
        .min(getTodayDate(), "Start Date must be today or future date")
        .required("This field is required"),
      maxUsers: Yup.number().positive(),
      maxBudget: Yup.number().positive(),
      expiryDate: Yup.date()
        .min(Yup.ref("startDate"), "Expiry date must be after the start date")
        .test(
          "is-greater",
          "Expiry date must be after the start date",
          function (value) {
            const { startDate } = this.parent;
            if (!value) return true; // Pass if endDate is not provided (optional)
            return new Date(value) > new Date(startDate);
          }
        )
        .nullable(),
      isNotifyUser: Yup.string().required("This field is required"),
      isSingleUse: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const {
        serviceType,
        maxBudget,
        isNotifyUser,
        maxUsers,
        discountValue,
        isSingleUse,
        ...rest
      } = values;

      const res: any = await new ApiManager().addPromo({
        ...rest,
        serviceType: serviceType.includes("all")
          ? serviceTypes.map((service) => service.value)
          : serviceType,
        maxUsers: parseInt(maxUsers),
        maxBudget: parseInt(maxBudget),
        discountValue: parseInt(discountValue),
        isNotifyUser: isNotifyUser === "true" ? true : false,
        isSingleUse: isSingleUse === "true" ? true : false,
      });
      if (res.success) {
        toast("Promo Code Added Successfully", { type: "success" });
        resetForm();
        onSuccess();
        handleHide();
      } else {
        toast(res.error?.response?.data?.error, {
          type: "error",
        });
      }
    },
  });

  const handleServiceTypeChange = useCallback(
    (service: { label: string; value: string }, checked: boolean) => {
      let currentServiceTypes = [...formik.values.serviceType];
      if (currentServiceTypes.includes("all")) {
        currentServiceTypes = [];
      }
      if (checked) {
        formik.setFieldValue("serviceType", [
          ...currentServiceTypes,
          service.value,
        ]);
      } else {
        formik.setFieldValue(
          "serviceType",
          currentServiceTypes.filter((item) => item !== service.value)
        );
      }
    },
    [formik.values.serviceType, formik.setFieldValue]
  );

  useEffect(() => {
    if (
      formik.values.discountType &&
      formik.values.discountValue &&
      !adminAddedDescription
    ) {
      formik.setFieldValue(
        "description",
        `Enjoy ${formik.values.discountType === "flat" ? "$" : ""} ${
          formik.values.discountValue
        } ${
          formik.values.discountType === "percentage" ? "%" : ""
        } discount on this offer for ${formik.values.serviceType.map(
          (service) => `${serviceTypeMap[service]} Pickup`
        )}`
        // formik.values.discountType === "flat"
        //   ? `Enjoy $${
        //       formik.values.discountValue
        //     } discount on this offer for ${formik.values.serviceType.join(
        //       ", "
        //     )}`
        //   : `Enjoy ${
        //       formik.values.discountValue
        //     }% discount on this offer ${formik.values.serviceType.join(", ")}`
      );
    }
  }, [
    formik.values.discountType,
    formik.values.discountValue,
    formik.values.serviceType,
    adminAddedDescription,
  ]);

  return (
    <Modal
      isVisible={isVisible}
      onHide={() => {
        formik.resetForm();
        handleHide();
      }}
      containerClassName="lg:max-w-4xl"
      heading="Add Promo Code"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-7 gap-x-4 gap-y-2">
          <div className="grid col-span-5 grid-cols-5 gap-x-4 ">
            <Input
              placeholder="TEST123"
              isInvalid={Boolean(
                formik.touched.promoCode && formik.errors.promoCode
              )}
              className="input-primary"
              containerClassName="col-span-3"
              label="Promo Code *"
              name="promoCode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.promoCode}
              value={formik.values.promoCode}
            />
            <Input
              type="date"
              isInvalid={Boolean(
                formik.touched.startDate && formik.errors.startDate
              )}
              containerClassName="col-span-3"
              label="Valid From *"
              className="input-primary"
              name="startDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.startDate}
              value={formik.values.startDate}
            />

            <Select
              placeholder="Select a Discount Type"
              isInvalid={Boolean(
                formik.touched.discountType && formik.errors.discountType
              )}
              className="select-primary"
              containerClassName="col-span-2"
              label="Discount Type *"
              name="discountType"
              value={formik.values.discountType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.discountType}
              options={discountOptions}
            />
            <Input
              containerClassName="pb-0 mt-1"
              rightContent={
                <div className="flex-shrink-0 border-r border-b border-t border-primary flex h-full items-center justify-center bg-gray-200 w-8 rounded-r-lg">
                  {formik.values.discountType === "flat"
                    ? "$"
                    : formik.values.discountType === "percentage"
                    ? "%"
                    : ""}
                </div>
              }
              placeholder="Enter Value"
              isInvalid={Boolean(
                formik.touched.discountValue && formik.errors.discountValue
              )}
              label="Of *"
              className="input-primary"
              name="discountValue"
              value={formik.values.discountValue}
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.discountValue}
            />
            <Input
              containerClassName="pb-0 mt-1"
              rightContent={
                <div className="flex-shrink-0 border-r border-b border-t border-primary flex h-full items-center justify-center bg-gray-200 w-8 rounded-r-lg">
                  <FaUser />
                </div>
              }
              placeholder="Enter Value"
              type="number"
              isInvalid={Boolean(
                formik.touched.maxUsers && formik.errors.maxUsers
              )}
              className="input-primary"
              label="Max Users"
              name="maxUsers"
              onChange={formik.handleChange}
              value={formik.values.maxUsers}
              onBlur={formik.handleBlur}
              error={formik.errors.maxUsers}
            />
            <Input
              containerClassName="pb-0 mt-1"
              rightContent={
                <div className="flex-shrink-0 border-r border-b border-t border-primary flex h-full items-center justify-center bg-gray-200 w-8 rounded-r-lg">
                  $
                </div>
              }
              placeholder="Enter Value"
              type="number"
              className="input-primary"
              isInvalid={Boolean(
                formik.touched.maxBudget && formik.errors.maxBudget
              )}
              label="Max Budget"
              name="maxBudget"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.maxBudget}
              value={formik.values.maxBudget}
            />
            <Textarea
              containerClassName="col-span-6"
              placeholder="Test Discount"
              className="textarea-primary"
              isInvalid={Boolean(
                formik.touched.description && formik.errors.description
              )}
              label="Discount Description *"
              name="description"
              onChange={(e) => {
                formik.setFieldValue("description", e.target.value);
                if (e.target.value === "") {
                  setAdminAddedDescription(false);
                } else {
                  setAdminAddedDescription(true);
                }
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.description}
              value={formik.values.description}
            />
          </div>
          <div className="grid col-span-2 grid-cols-1 gap-2">
            <Input
              type="date"
              isInvalid={Boolean(
                formik.touched.expiryDate && formik.errors.expiryDate
              )}
              label="Valid Till (Optional)"
              name="expiryDate"
              className="input-primary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.expiryDate}
              value={formik.values.expiryDate ? formik.values.expiryDate : ""}
            />
            <div>
              <div className="label">
                <span className="label-text">Service Type *</span>
              </div>
              {/* <div className="grid grid-cols-2"> */}
              <Checkbox
                containerClassName="w-14"
                onChange={(e) => {
                  if (e.target.checked) {
                    formik.setFieldValue("serviceType", ["all"]);
                  } else {
                    formik.setFieldValue("serviceType", []);
                  }
                }}
                label="All"
                checked={formik.values.serviceType.includes("all")}
              />
              {serviceTypes.map((service) => (
                <Checkbox
                  containerClassName="w-32"
                  key={service.value}
                  onChange={(e) =>
                    handleServiceTypeChange(service, e.target.checked)
                  }
                  label={service.label}
                  checked={formik.values.serviceType.includes(service.value)}
                />
              ))}
              {/* </div> */}
              {formik.touched.serviceType && formik.errors.serviceType ? (
                <div className="label">
                  <span className="label-text-alt text-red-600">
                    {formik.errors.serviceType}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="-space-y-1">
              <Toggle
                labelContainerClassName="flex justify-start gap-4"
                checked={formik.values.isNotifyUser === "true"}
                name="isNotifyUser"
                onChange={(e) =>
                  formik.setFieldValue(
                    "isNotifyUser",
                    e.target.checked.toString()
                  )
                }
                isInvalid={Boolean(
                  formik.touched.isNotifyUser && formik.errors.isNotifyUser
                )}
                error={formik.errors.isNotifyUser}
                label="Notify User"
              />
              <Toggle
                labelContainerClassName="flex justify-start gap-[1.32rem]"
                checked={formik.values.isSingleUse === "true"}
                name="isSingleUse"
                onChange={(e) =>
                  formik.setFieldValue(
                    "isSingleUse",
                    e.target.checked.toString()
                  )
                }
                isInvalid={Boolean(
                  formik.touched.isSingleUse && formik.errors.isSingleUse
                )}
                error={formik.errors.isSingleUse}
                label="Single Use"
              />
            </div>
          </div>
        </div>
        <div className="flex mt-4 gap-2 items-center justify-center">
          {/* <Button
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            type="button"
            variant="outline"
            className="w-20"
          >
            Close
          </Button> */}
          <Button
            className="w-32"
            isLoading={formik.isSubmitting}
            type="submit"
          >
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddPromoCodeModal;
