import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import Button from "../components/Button";
import DataTable, { TBody, TH, THead, TR } from "../components/DataTable";
import ViewDetails, { ComplaintType } from "../components/HelpDesk/ViewDetails";
import Pagination from "../components/Pagination";
import Spinner from "../components/Spinner";
import ApiManager from "../utils/ApiManager";
import { capitalize } from "../utils/helpers";
import FiltersContainer from "../components/FiltersContainer";
import commentsIcon from "../assets/comments.svg";
import ChatModal from "../components/HelpDesk/ChatModal";

interface ComplaintBaseResponse {
  createdAt: string;
  curbside_pickup_meta?: {};
  instore_pickup_meta?: {};
  package_pickup_meta?: {};
  description: string;
  userType: "user" | "driver";
  _id: string;
  title: string;
  serviceId: string;
}

interface UserResponse extends ComplaintBaseResponse {
  userType: "user";
  reportingUser: {
    email: string;
    fullName: string;
    gender: string;
    phone: string;
    profileImage: string;
    _id: string;
  };
}

interface DriverResponse extends ComplaintBaseResponse {
  userType: "driver";
  reportingDriver: {
    email: string;
    fullName: string;
    gender: string;
    phone: string;
    profileImage: string;
    _id: string;
    vehicleNumber: string;
    vehicleType: string;
  };
}

export type ApiResponse = UserResponse | DriverResponse;

const HelpDesk = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<ApiResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userType, setUserType] = useState("");
  const [selectedComplaint, setSelectedComplaint] =
    useState<ApiResponse | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  // const { data, isFetching } = useGetAllComplaintsQuery({
  //   limit: 10,
  //   page: currentPage,
  //   userType,
  // });

  const handleGetAllComplaints = async () => {
    setIsFetching(true);
    const res = await new ApiManager().getAllComplaints({
      limit: 10,
      page: currentPage,
      userType,
    });
    if (res.success) {
      setData(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    handleGetAllComplaints();
  }, [currentPage, userType]);

  return (
    <>
      <Helmet>
        <title> Help Desk</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="pb-4  flex flex-col justify-center font-inter bg-gray-100 ">
          <FiltersContainer>
            <div className="flex items-center justify-end my-4">
              <select
                defaultValue=""
                className="outline-none bg-white rounded-lg h-12 pl-4 pr-2 ml-4"
                value={userType}
                onChange={(e) => {
                  setCurrentPage(1);
                  setUserType(e.target.value);
                }}
              >
                <option value="" disabled hidden>
                  User Type
                </option>
                <option value="user">User</option>
                <option value="driver">Driver</option>
              </select>
              <Button
                disabled={userType === ""}
                type="button"
                className="btn-outline w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary"
                onClick={() => {
                  setCurrentPage(1);
                  setUserType("");
                }}
              >
                Clear
              </Button>
            </div>
          </FiltersContainer>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {isFetching ? (
              <Spinner />
            ) : !isFetching && data?.length === 0 ? (
              <div className="w-full  h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Data Found
                </p>
              </div>
            ) : (
              <DataTable>
                <THead>
                  <TR>
                    <TH>User</TH>
                    <TH>Title</TH>
                    <TH>Type</TH>
                    <TH>Description</TH>
                    <TH>Actions</TH>
                  </TR>
                </THead>
                <TBody>
                  {data?.map((item: ApiResponse, index: number) => (
                    <TR key={index}>
                      <TH>
                        {item?.userType === "user"
                          ? item?.reportingUser?.fullName || "N/A"
                          : item?.reportingDriver?.fullName || "N/A"}
                      </TH>
                      <TH className="flex items-center">
                        <p className="shrink-0">
                          {item?.title?.slice(0, 30) || "N/A"}{" "}
                        </p>
                        {item?.title?.length > 30 && (
                          <p
                            className="cursor-pointer shrink-0 text-xs"
                            onClick={() => {
                              setSelectedComplaint(item);
                              setIsDetailsVisible(true);
                            }}
                          >
                            ...Read More
                          </p>
                        )}
                      </TH>
                      <TH>
                        {item.userType ? capitalize(item?.userType) : "N/A"}
                      </TH>
                      <TH className="flex items-center">
                        {item?.description === "" ? (
                          <p>N/A</p>
                        ) : (
                          <>
                            <p className="shrink-0">
                              {item?.description?.slice(0, 30)}
                            </p>

                            {item?.description?.length > 30 && (
                              <p
                                className="cursor-pointer text-xs shrink-0"
                                onClick={() => {
                                  setSelectedComplaint(item);
                                  setIsDetailsVisible(true);
                                }}
                              >
                                ...Read More
                              </p>
                            )}
                          </>
                        )}
                      </TH>
                      <TH>
                        <div className="flex gap-10 items-center">
                          <button
                            onClick={() => {
                              setSelectedComplaint(item);
                              setIsChatVisible(true);
                            }}
                          >
                            <img
                              className="w-6"
                              style={{
                                filter:
                                  "brightness(0) saturate(100%) invert(80%) sepia(38%) saturate(7016%) hue-rotate(50deg) brightness(89%) contrast(97%)",
                              }}
                              src={commentsIcon}
                              alt="Chat"
                            />
                          </button>
                          {item._id ? (
                            <button
                              onClick={() => {
                                setSelectedComplaint(item);
                                setIsDetailsVisible(true);
                              }}
                              className="text-primary"
                            >
                              View
                            </button>
                          ) : null}
                          {item?.serviceId ? (
                            <Link
                              to={`/dashboard/services/${item?.serviceId}`}
                              className="text-primary"
                            >
                              <button className="text-primary">
                                View Service Details
                              </button>
                            </Link>
                          ) : null}
                          {!item._id && !item.serviceId ? <p>N/A</p> : null}
                        </div>
                      </TH>
                    </TR>
                  ))}
                </TBody>
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              currentPage={currentPage}
              page={totalPages}
              handlePageClick={(e: { selected: number }) => {
                setCurrentPage(e.selected + 1);
              }}
            />
          </div>
        </div>
      </div>

      <ViewDetails
        isVisible={isDetailsVisible}
        onHide={() => {
          setSelectedComplaint(null);
          setIsDetailsVisible(false);
        }}
        id={selectedComplaint?._id || ""}
      />
      <ChatModal
        complaint={selectedComplaint as ComplaintType}
        isVisible={isChatVisible}
        onHide={() => {
          setSelectedComplaint(null);
          setIsChatVisible(false);
        }}
      />
    </>
  );
};

export default HelpDesk;
