export const COOKIE_KEYS = {
  JWT: "M4rP>pF%RN{2v8%B",
};

export const statusMap = (status: string, className?: string) => {
  const map: { [key: string]: { text: string } } = {
    arrivedAtDropoff: {
      text: "Arrived At Dropoff",
    },
    unassigned: {
      text: "Unassigned",
    },
    notProcessable: {
      text: "Not Processable",
    },
    assigned: {
      text: "Assigned",
    },
    wayToStore: {
      text: "Way To Store",
    },
    arrivedAtPickup: {
      text: "Arrived At Pickup",
    },
    confirmItems: {
      text: "Confirm Items",
    },
    proceedToDropoff: {
      text: "Proceed To Dropoff",
    },
    confirmDropoff: {
      text: "Confirm Dropoff",
    },
    delivered: {
      text: "Delivered",
    },
    ratedByUser: {
      text: "Completed",
    },
    ratedByDriver: {
      text: "Rated By Driver",
    },
    deliver: {
      text: "Deliver",
    },
    completed: {
      text: "Completed",
    },
    cancelled: {
      text: "Cancelled",
    },
  };

  const { text } = map[status];

  return text;
};

export const VERIFICATION_STATUS = {
  PENDING_REVIEW: "pending_review",
  APPROVED: "approved",
  RESUBMIT: "resubmitted",
  REJECTED: "rejected",
};

export const ADMIN_TYPES = {
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin",
};
