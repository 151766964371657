import { FormikProps } from "formik";
import React from "react";
import Input from "../Input";
import Button from "../Button";

interface PropTypes {
  step1Form: FormikProps<{ email: string }>;
}

const EmailForm = ({ step1Form }: PropTypes) => {
  return (
    <form
      onSubmit={step1Form.handleSubmit}
      className="bg-white flex flex-col gap-4 p-4 lg:w-[30%] rounded"
    >
      <p className="text-gray-500">
        Enter the email address associated with your account and we will send
        you a one time password (otp) to reset your password.
      </p>
      <Input
        type="email"
        name="email"
        onChange={step1Form.handleChange}
        value={step1Form.values.email}
        onBlur={step1Form.handleBlur}
        error={step1Form.errors.email}
        placeholder="Please enter email"
        label="Enter Email"
      />
      <Button
        isLoading={step1Form.isSubmitting}
        type="submit"
        className="bg-primary text-white"
      >
        Send OTP
      </Button>
    </form>
  );
};

export default EmailForm;
