import React, { FormEvent, useEffect, useState } from "react";
import ReactSelect, { SingleValue } from "react-select";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import ApiManager from "../utils/ApiManager";
import Button from "./Button";
import Modal from "./Modal";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const AddStateModal = ({ isVisible, onHide, onSuccess }: PropTypes) => {
  const [allStates, setAllStates] = useState<
    { label: string; value: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    symbol: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    setIsSubmitting(true);
    e.preventDefault();
    const res: any = await new ApiManager().addState(form);
    if (res.success) {
      setForm({ name: "", symbol: "" });
      toast("State Added Successfully", { type: "success" });
      onHide();
      onSuccess();
    } else {
      toast(res?.error?.response?.data?.error, { type: "error" });
    }
    setIsSubmitting(false);
  };

  const handleGetAllStates = async () => {
    setIsLoading(true);
    const res = await new ApiManager().getAllStates();
    if (res.success) {
      setAllStates(res.data?.states);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetAllStates();
  }, []);

  return (
    <Modal isVisible={isVisible} onHide={onHide}>
      {isLoading ? (
        <div className="flex h-full py-10 items-center justify-center">
          <Spinner loaderClassName="m-0" />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="px-6">
          <h1 className="text-lg text-center font-bold">Add State</h1>
          <div className="flex flex-col gap-4">
            <div>
              <label htmlFor="state" className="label">
                State *
              </label>
              <ReactSelect
                menuPortalTarget={document.body}
                onChange={(e: SingleValue<{ label: string; value: string }>) =>
                  setForm({ name: e?.label!, symbol: e?.value! })
                }
                placeholder="Select a state"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: "0.5rem",
                    border: "1px solid #58b304",
                    boxShadow: "none",
                    padding: "0.25rem",
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                id="state"
                required
                options={allStates}
              />
            </div>
          </div>
          <div className="w-full items-center justify-center flex gap-4 mt-4">
            <Button isLoading={isSubmitting} type="submit" className="px-10">
              Add
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddStateModal;
