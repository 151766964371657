import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  heading?: string;
  children: React.ReactNode;
  containerClassName?: string;
  modalClassName?: string;
}

const Modal = ({
  isVisible,
  onHide,
  children,
  heading,
  containerClassName,
  modalClassName,
}: PropTypes) => {
  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className={twMerge(
          "fixed z-50 inset-0 overflow-y-auto",
          modalClassName
        )}
        onClose={onHide}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={twMerge(
                "inline-block p-4 border border-primary align-bottom bg-[#EEEFF1] rounded-2xl text-left overflow-hidden shadow-xl transform transition-all lg:max-w-3xl sm:align-middle sm:max-w-lg sm:w-full",
                containerClassName
              )}
            >
              <div className="inline-block p-4 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all lg:w-full sm:align-middle sm:w-full">
                <div className="w-full flex justify-end">
                  <AiOutlineClose className="cursor-pointer" onClick={onHide} />
                </div>
                {heading && (
                  <h1 className="text-center font-bold text-2xl">{heading}</h1>
                )}

                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
