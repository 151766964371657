import Cookies from "js-cookie";

const in60Minutes = 1 / 24;

export const setSessionCookie = (
  SESSION_NAME: string,
  SESSION_VALUE: string,
  DURATION: Date
) => {
  deleteSessionCookie(SESSION_NAME);
  Cookies.set(SESSION_NAME, SESSION_VALUE, {
    expires: DURATION || in60Minutes,
  });
};

export const getSessionCookie = (SESSION_NAME: string) => {
  const sessionCookie = Cookies.get(SESSION_NAME);
  return sessionCookie === undefined ? false : sessionCookie;
};

export const deleteSessionCookie = (SESSION_NAME: string) => {
  Cookies.remove(SESSION_NAME);
};
