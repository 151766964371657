import React from "react";
import { PromoCodeType } from "../pages/PromoCodes.js";
import { formatDate } from "../utils/helpers";
import Modal from "./Modal";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  promoCode: PromoCodeType | null;
}

const ViewPromoCode = ({ isVisible, onHide, promoCode }: PropTypes) => {
  return (
    <Modal isVisible={isVisible} onHide={onHide}>
      <div className="w-full flex flex-col justify-center gap-4 h-full">
        <p>
          <strong>Description: </strong>
          {promoCode?.description || "N/A"}
        </p>
        <p>
          <strong>Start Date: </strong>
          {promoCode?.startDate ? formatDate(promoCode.startDate) : "N/A"}
        </p>
        <p>
          <strong>Expiry Date: </strong>
          {promoCode?.expiryDate ? formatDate(promoCode.expiryDate) : "N/A"}
        </p>
        <p>
          <strong>Max Users: </strong>
          {promoCode?.maxUsers || "N/A"}
        </p>
      </div>
    </Modal>
  );
};

export default ViewPromoCode;
