import { FormikProps } from "formik";
import React from "react";
import Button from "../Button";
import PasswordInput from "../PasswordInput";

interface PropTypes {
  step3Form: FormikProps<{ password: string }>;
}

const NewPassword = ({ step3Form }: PropTypes) => {
  return (
    <form
      onSubmit={step3Form.handleSubmit}
      className="bg-white flex flex-col gap-4 p-4 lg:w-[30%] rounded"
    >
      <p className="text-gray-500">Enter New Password</p>
      <PasswordInput
        type="password"
        name="password"
        isInvalid={Boolean(
          step3Form.touched.password && step3Form.errors.password
        )}
        onChange={step3Form.handleChange}
        value={step3Form.values.password}
        onBlur={step3Form.handleBlur}
        error={step3Form.errors.password}
        placeholder="Please enter password"
        label="Enter Password"
      />
      <Button
        isLoading={step3Form.isSubmitting}
        type="submit"
        className="bg-primary text-white"
      >
        Save
      </Button>
    </form>
  );
};

export default NewPassword;
