import { createSlice } from "@reduxjs/toolkit";

type SliceType = {
  user: null | {
    _id: string;
    firstName: string;
    lastName: string;
    username: string;
    userType: "admin" | "super_admin";
    fcm: string[];
  };
  isLoggedIn: boolean;
  isLoggingIn: boolean;
};

const initialState: SliceType = {
  user: null,
  isLoggedIn: false,
  isLoggingIn: false,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    isLoggingIn: (state, action) => {
      state.isLoggingIn = action.payload;
    },
  },
});

export const { login, logout, isLoggingIn } = authSlice.actions;

export default authSlice.reducer;
