import { FormikProps } from "formik";
import React from "react";
import Input from "../Input";
import Button from "../Button";

interface PropTypes {
  step2Form: FormikProps<{ otp: string }>;
  isResendDisabled: boolean;
  onResend: () => void;
  countDown: string;
  resendAttempts: number;
}

const OTPForm = ({
  step2Form,
  isResendDisabled,
  onResend,
  countDown,
  resendAttempts,
}: PropTypes) => {
  return (
    <form
      onSubmit={step2Form.handleSubmit}
      className="bg-white flex flex-col gap-4 p-4 lg:w-[30%] rounded"
    >
      <p className="text-gray-500">
        Please check your email and enter the OTP you recieved.
      </p>
      <Input
        type="number"
        name="otp"
        onChange={step2Form.handleChange}
        value={step2Form.values.otp}
        onBlur={step2Form.handleBlur}
        error={step2Form.errors.otp}
        placeholder="Please enter otp"
        label="Enter OTP"
      />
      <div className="flex justify-end">
        {resendAttempts < 2 ? (
          <Button
            onClick={onResend}
            disabled={isResendDisabled}
            type="button"
            className="btn-ghost text-primary"
          >
            {isResendDisabled ? `Resend Otp in ${countDown}` : "Resend OTP"}
          </Button>
        ) : (
          <p>Maximum resend attempts reached. Please try again later.</p>
        )}
      </div>

      <Button
        isLoading={step2Form.isSubmitting}
        type="submit"
        className="bg-primary text-white"
      >
        Verify OTP
      </Button>
    </form>
  );
};

export default OTPForm;
