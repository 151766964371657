import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import logo from "../assets/logo.png";
import EmailForm from "../components/ForgotPassword/EmailForm";
import OTPForm from "../components/ForgotPassword/OtpForm";
import ApiManager from "../utils/ApiManager";
import NewPassword from "../components/ForgotPassword/NewPassword";

const ForgotPassword = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [searchParams] = useSearchParams();
  const [countdown, setCountdown] = useState(0);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [token, setToken] = useState("");

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const email = searchParams.get("email");
  const navigate = useNavigate();

  const step1Form = useFormik({
    initialValues: {
      email: email ?? "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("This field is required"),
    }),
    onSubmit: async (values) => {
      const res: any = await new ApiManager().sendOtp(values.email);
      if (res.error) {
        toast(
          res.error?.response?.data?.message ||
            "Something went/ wrong, please try again later.",
          { type: "error" }
        );
        return;
      }
      toast(res.data?.message, { type: "success" });
      setCurrentStep(2);
      setIsResendDisabled(true);
      setResendAttempts(0);
      startCountdown(60);
    },
  });

  const step2Form = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object().shape({
      otp: Yup.number().required("This field is required"),
    }),
    onSubmit: async (values) => {
      const res = await new ApiManager().verifyOtp({
        code: parseInt(values.otp),
        email: step1Form.values.email,
      });
      console.log(res, "Res");
      if (!res.success) {
        toast("Invalid OTP", { type: "error" });
        return;
      }
      toast(res.data?.message, { type: "success" });
      setToken(res.data?.token);
      setCurrentStep(3);
    },
  });

  const step3Form = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters long")
        .required("This field is required"),
    }),
    onSubmit: async (values) => {
      const res: any = await new ApiManager().resetPassword({
        password: values.password,
        token,
      });
      if (!res.success) {
        toast(
          res.error?.response?.data?.message ||
            "Something went/ wrong, please try again later.",
          { type: "error" }
        );
        return;
      }
      toast(res.data?.message, { type: "success" });
      navigate("/login");
    },
  });

  const handleResendOtp = async () => {
    if (isResendDisabled) return;
    const newAttempts = resendAttempts + 1;
    setResendAttempts(newAttempts);
    const res: any = await new ApiManager().sendOtp(step1Form.values.email);
    if (res.error) {
      toast(
        res.error?.response?.data?.message ||
          "Something went wrong, please try again later.",
        { type: "error" }
      );
      return;
    }
    toast(res.data?.message, { type: "success" });
    setIsResendDisabled(true);
    if (newAttempts === 1) {
      startCountdown(300); // 5 minutes
    } else if (newAttempts >= 2) {
      setIsResendDisabled(true);
    }
  };
  const startCountdown = useCallback((duration: number) => {
    setCountdown(duration);
    setIsResendDisabled(true);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timerRef.current!);
          setIsResendDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (currentStep === 2) {
      startCountdown(60);
    }
  }, [currentStep, startCountdown]);

  const formatCountdown = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - Swyvery Admin</title>
      </Helmet>
      <div className="w-full bg-gray-100 min-h-screen flex flex-col items-center justify-center">
        <img src={logo} alt="Swyvery Logo" />
        {currentStep === 1 ? (
          <EmailForm step1Form={step1Form} />
        ) : currentStep === 2 ? (
          <OTPForm
            isResendDisabled={isResendDisabled}
            onResend={handleResendOtp}
            countDown={formatCountdown(countdown)}
            resendAttempts={resendAttempts}
            step2Form={step2Form}
          />
        ) : currentStep === 3 ? (
          <NewPassword step3Form={step3Form} />
        ) : null}
      </div>
    </>
  );
};

export default ForgotPassword;
