// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaEye, FaEyeSlash, FaInfo } from "react-icons/fa6";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import { instance } from "../utils/ApiManager";
import Button from "./Button";
import DataTable, { TBody, TH, THead, TR } from "./DataTable";
import DeleteFeedback from "./DeleteFeedback";
import ExperienceRating from "./ExperienceRating";
import FiltersContainer from "./FiltersContainer";
import Pagination from "./Pagination";
import Spinner from "./Spinner";
import ViewFeedback from "./ViewFeedback";

export const experienceRatingMap: { [key: number]: string } = {
  1: "Terrible",
  2: "Bad",
  3: "Average",
  4: "Good",
  5: "Amazing",
};

const Feedback = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Get values from URL params with defaults
  const app = searchParams.get("app") || "user";
  const currentPage = parseInt(searchParams.get("page")) || 1;

  const [isViewFeedbackVisible, setViewFeedbackVisible] = useState(false);
  const [isDeleteFeedbackVisbile, setDeleteFeedbackVisible] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [feedbacks, setFeedbacks] = useState([]);
  const [unreadCount, setUnreadCount] = useState({ user: null, driver: null });
  const [loading, setLoading] = useState(false);
  const [loadingRow, setLoadingRow] = useState("");

  const titleMap = { user: "User", driver: "Driver" };

  const handlePageClick = (e) => {
    setSearchParams((prev) => {
      prev.set("page", (e.selected + 1).toString());
      return prev;
    });
  };

  const handleAppChange = (newApp) => {
    setSearchParams((prev) => {
      prev.set("app", newApp);
      prev.set("page", "1"); // Reset to first page when changing apps
      return prev;
    });
  };

  const handleDeleteFeedback = async () => {
    const res = await instance.delete(
      `/admin/feedback/${selectedFeedback?._id}/delete`
    );
    if (res.status === 200) {
      setDeleteFeedbackVisible(false);
      toast("Feedback deleted successfully", { type: "success" });
      const arr = [...feedbacks];
      const index = arr.findIndex(
        (item) => item?._id === selectedFeedback?._id
      );
      if (arr.at(index)?.status === "unread") {
        setUnreadCount((prev) => ({ ...prev, [app]: prev[app] - 1 }));
      }
      arr.splice(index, 1);
      setFeedbacks(arr);
      setSelectedFeedback(undefined);
    }
  };

  const getUnreadCounts = async () => {
    const urls = [
      "/admin/feedback/unread/count?userType=user",
      "/admin/feedback/unread/count?userType=driver",
    ];
    const iterable = urls.map((url) => instance.get(url));
    const [userCount, driverCount] = await Promise.all(iterable);
    setUnreadCount({
      user: userCount.data?.unreadCount,
      driver: driverCount.data?.unreadCount,
    });
  };

  const getFeedback = async () => {
    setLoading(true);
    const res = await instance.get(`/admin/feedback/all`, {
      params: { limit: 10, userType: app, page: currentPage },
    });
    if (res.status === 200) {
      setFeedbacks(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setLoading(false);
  };

  const modifyFeedbackStatus = async (item, index) => {
    setLoadingRow(item?._id);
    const newStatus = item?.status === "read" ? "unread" : "read";
    const res = await instance.patch(`/admin/feedback/${item?._id}/modify`, {
      status: newStatus,
    });
    if (res.status === 200) {
      setUnreadCount((prev) => ({
        ...prev,
        [app]: item?.status === "read" ? prev[app] + 1 : prev[app] - 1,
      }));
      let arr = [...feedbacks];
      arr[index].status = newStatus;
      setFeedbacks(arr);
    }
    setLoadingRow("");
  };

  const modifyFeedbackStatusOnView = async (item, index) => {
    if (item?.status === "read") {
      return;
    }
    setLoadingRow(item?._id);
    const newStatus = "read";
    const res = await instance.patch(`/admin/feedback/${item?._id}/modify`, {
      status: newStatus,
    });
    if (res.status === 200) {
      setUnreadCount((prev) => ({
        ...prev,
        [app]: item?.status === "read" ? prev[app] + 1 : prev[app] - 1,
      }));
      let arr = [...feedbacks];
      arr[index].status = newStatus;
      setFeedbacks(arr);
    }
    setLoadingRow("");
  };

  useEffect(() => {
    getFeedback();
  }, [app, currentPage]);

  useEffect(() => {
    getUnreadCounts();
  }, []);

  return (
    <div className="w-full h-full">
      <Helmet>
        <title>{titleMap[app]} App Feedback</title>
      </Helmet>

      <div className="pb-4 flex flex-col justify-center font-inter bg-gray-100 ">
        <FiltersContainer>
          <div className="flex items-center justify-end my-4">
            <div className="px-3 py-3 bg-white rounded-full">
              <button
                onClick={() => handleAppChange("user")}
                className={`${
                  app === "user"
                    ? "bg-primary text-white"
                    : "bg-transparent text-primary"
                } rounded-full w-32 py-2 relative`}
              >
                User App
                {unreadCount.user > 0 ? (
                  <div className="w-6 h-6 flex items-center justify-center absolute -top-1 right-0 text-xs rounded-full bg-red-600 text-white">
                    {unreadCount.user}
                  </div>
                ) : null}
              </button>
              <button
                onClick={() => handleAppChange("driver")}
                className={`${
                  app === "driver"
                    ? "bg-primary text-white"
                    : "bg-transparent text-primary"
                } rounded-full w-32 py-2 relative`}
              >
                Driver App
                {unreadCount.driver > 0 ? (
                  <div className="w-6 h-6 flex items-center justify-center absolute -top-1 right-0 text-xs rounded-full bg-red-600 text-white">
                    {unreadCount.driver}
                  </div>
                ) : null}
              </button>
            </div>
          </div>
        </FiltersContainer>
        <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
          {loading ? (
            <Spinner />
          ) : !loading && feedbacks.length === 0 ? (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <img className="w-1/5" src={empty} />
              <p className="mt-10 text-2xl text-gray-500 font-inter">
                No Feedbacks Found
              </p>
            </div>
          ) : (
            <DataTable className="w-full h-4/5 overflow-y-auto overflow-x-auto">
              <THead>
                <TR>
                  <TH>{app} Name</TH>
                  <TH>Feedback</TH>
                  <TH>Experience Rating</TH>
                  <TH>Actions</TH>
                </TR>
              </THead>
              <TBody>
                {feedbacks?.map((item, index) => (
                  <TR key={index}>
                    <TH>{item[app][0]?.fullName || "N/A"}</TH>
                    <TH className="flex items-center pt-10">
                      {item.feedback ? (
                        <div className="max-w-xs flex items-center overflow-hidden ">
                          <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                            {item?.feedback}
                          </p>
                          {item.feedback.length > 30 && (
                            <span
                              onClick={() => {
                                setSelectedFeedback(item);
                                setViewFeedbackVisible(true);
                              }}
                              className="cursor-pointer text-xs flex-shrink-0"
                            >
                              Read More
                            </span>
                          )}
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </TH>
                    <TH>
                      <div className="flex flex-col gap-2">
                        <div className="flex items-start gap-4">
                          <ExperienceRating
                            emojiClassName="w-10 h-10"
                            showLabel
                            feedback={item}
                          />
                        </div>
                      </div>
                    </TH>
                    <TH>
                      <Button
                        onClick={() => modifyFeedbackStatus(item, index)}
                        disabled={loadingRow === item?._id}
                        className={twMerge(
                          "btn-primary",
                          item?.status === "read" ? "btn-outline" : ""
                        )}
                      >
                        {item?.status === "read" ? (
                          <FaEyeSlash />
                        ) : (
                          <FaEye
                            color={loadingRow === item?._id ? "black" : "white"}
                          />
                        )}
                      </Button>

                      <Button
                        onClick={() => {
                          setSelectedFeedback(item);
                          setViewFeedbackVisible(true);
                          modifyFeedbackStatusOnView(item, index);
                        }}
                        className="ml-4 btn-outline btn-primary"
                      >
                        <FaInfo />
                      </Button>
                    </TH>
                  </TR>
                ))}
              </TBody>
            </DataTable>
          )}
        </div>
        <div className="mt-6">
          <Pagination
            currentPage={currentPage}
            page={totalPages}
            handlePageClick={(e) => handlePageClick(e)}
          />
        </div>
      </div>
      <ViewFeedback
        isVisible={isViewFeedbackVisible}
        setVisible={setViewFeedbackVisible}
        feedback={selectedFeedback}
      />
      <DeleteFeedback
        isVisible={isDeleteFeedbackVisbile}
        setVisible={setDeleteFeedbackVisible}
        onSubmit={handleDeleteFeedback}
      />
    </div>
  );
};

export default Feedback;
