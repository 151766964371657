import React, { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

interface PropTypes extends ComponentProps<"input"> {
  label?: string;
  isInvalid?: boolean;
  error?: string;
  labelClassName?: string;
  toggleClassName?: string;
  labelContainerClassName?: string;
}

const Toggle = ({
  label,
  isInvalid,
  error,
  labelClassName,
  toggleClassName,
  labelContainerClassName,
  disabled,
  checked,
  ...rest
}: PropTypes) => {
  return (
    <div className="form-control">
      {label ? (
        <div className={twMerge("label", labelContainerClassName)}>
          <span className={twMerge("label-text", labelClassName)}>{label}</span>
          <input
            {...rest}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            className={twMerge(
              "toggle",
              checked
                ? "bg-white border-white hover:bg-white  [--tglbg:#58b304] "
                : "bg-primary border-none hover:bg-primary  [--tglbg:#f3f4f6]",
              toggleClassName
            )}
          />
        </div>
      ) : (
        <input
          {...rest}
          checked={checked}
          type="checkbox"
          disabled={disabled}
          className={twMerge("toggle toggle-primary", toggleClassName)}
        />
      )}
      {isInvalid ? (
        <div className="label">
          <span className="label-text-alt text-red-600">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default Toggle;
