import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { twMerge } from "tailwind-merge";
import * as Yup from "yup";
import { useAppSelector } from "../../Redux/store";
import ApiManager from "../../utils/ApiManager";
import { formatDate } from "../../utils/helpers";
import Button from "../Button";
import Input from "../Input";
import Modal from "../Modal";
import Spinner from "../Spinner";
import { ComplaintType, reportingMap } from "./ViewDetails";

interface ChatModalProps {
  isVisible: boolean;
  onHide: () => void;
  complaint: ComplaintType | null;
}

type MessageType = {
  _id: string;
  complaint: string;
  reply: string;
  createdAt: string;
  updatedAt: string;
  adminDetails: [
    {
      firstName: string;
      lastName: string;
    }
  ];
};

const MessageBubble = ({
  message,
  date,
  from,
  userName,
}: {
  message: string;
  from: "user" | "admin";
  date: string;
  userName?: string;
}) => {
  return (
    <div className={twMerge("w-1/2 space-y-2", from === "admin" && "self-end")}>
      {userName && (
        <p className="text-black text-end text-sm self-end">{userName}</p>
      )}
      <p
        className={twMerge(
          "bg-gray-200  rounded-tl-2xl rounded-br-2xl rounded-bl-2xl px-4 py-2",
          from === "admin" && "bg-primary text-white"
        )}
      >
        {message}
      </p>
      {date && <p className="text-gray-500 text-sm">{formatDate(date)}</p>}
    </div>
  );
};

const ChatModal = ({ isVisible, onHide, complaint }: ChatModalProps) => {
  const [replies, setReplies] = useState<MessageType[]>([]);
  const [isRepliesLoading, setIsRepliesLoading] = useState(false);

  const user = useAppSelector((state) => state.auth.user);

  const messageForm = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!complaint) return;
      const res = await new ApiManager().sendComplaintReply({
        complaint: complaint?._id,
        email: complaint[reportingMap[complaint?.userType]]?.email,
        reply: values.message,
      });
      if (res.success) {
        resetForm();
        const arr = [...replies];
        arr.push({
          adminDetails: [
            { firstName: user?.firstName, lastName: user?.lastName },
          ],
          ...res.data?.adminReply,
        } as MessageType);
        setReplies(arr);
      }
      console.log(res, "Res");
    },
  });

  const getComplaintReplies = async (id: string) => {
    setIsRepliesLoading(true);
    const res = await new ApiManager().getComplaintReplies(id);
    if (res.success) {
      setReplies(res.data);
    }
    setIsRepliesLoading(false);
  };

  useEffect(() => {
    if (complaint?._id) {
      getComplaintReplies(complaint?._id);
    }
  }, [complaint?._id]);

  return (
    <Modal isVisible={isVisible} onHide={onHide}>
      {complaint ? (
        <div className="space-y-4">
          <p className="pl-4 capitalize font-semibold text-lg">
            {complaint[reportingMap[complaint.userType]]?.fullName || "N/A"}
          </p>
          {isRepliesLoading ? (
            <Spinner />
          ) : (
            <>
              <div className="flex px-4 h-96 overflow-auto gap-y-2 flex-col mt-4">
                <MessageBubble
                  from="user"
                  message={complaint?.title || complaint?.description || "N/A"}
                  date={complaint?.createdAt}
                />

                {replies.map((reply) => (
                  <MessageBubble
                    key={reply._id}
                    date={reply.createdAt}
                    from="admin"
                    message={reply.reply}
                    userName={`${reply?.adminDetails[0]?.firstName} ${reply?.adminDetails[0]?.lastName} `}
                  />
                ))}
              </div>
              <form
                onSubmit={messageForm.handleSubmit}
                className="flex items-center w-full gap-2"
              >
                <Input
                  placeholder="Type a message"
                  containerClassName="w-full"
                  name="message"
                  onChange={messageForm.handleChange}
                  value={messageForm.values.message}
                />
                <Button
                  isLoading={messageForm.isSubmitting}
                  type="submit"
                  className="flex-shrink-0 w-14 h-14 rounded-full bg-primary flex items-center justify-center"
                >
                  <IoMdSend className="text-white" />
                </Button>
              </form>
            </>
          )}
        </div>
      ) : null}
    </Modal>
  );
};

export default ChatModal;
