import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ChangePasswordForm from "../components/Settings/ChangePasswordForm";
import VersionSupportForm from "../components/Settings/VersionSupportForm";
import { logout } from "../Redux/slices/auth";
import ApiManager from "../utils/ApiManager";

export type ChangePasswordType = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type VersionSupportType = {
  latestVersion: string;
  isUpdateRequired: string;
  userType: string;
  buildNumber: string;
};

const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old Password is required"),
  newPassword: Yup.string().required("New Password is required"),
  confirmNewPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword")], "New and Confirm Password must match."),
});

const versionSupportValidationSchema = Yup.object().shape({
  latestVersion: Yup.string()
    .required("Latest Version is required")
    .matches(
      /^0\.0\.\d+$/,
      "Invalid Version Format. The version should be in 0.0.X format."
    ),
  isUpdateRequired: Yup.number().required("Update Required field is required"),
  userType: Yup.string().required("User Type is required"),
  buildNumber: Yup.string().required("Build Number is required"),
});

const AccountSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePasswordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: async (values) => {
      const res = await new ApiManager().changePassword(
        values.oldPassword,
        values.newPassword
      );
      if (res.success) {
        new ApiManager().logout();
        dispatch(logout());
        navigate("/login");
        toast(res?.payload?.data.message, { type: "success" });
      } else {
        toast(res?.error, { type: "error" });
      }
    },
  });

  const versionSupportFormik = useFormik({
    initialValues: {
      latestVersion: "",
      isUpdateRequired: "0",
      userType: "user",
      buildNumber: "",
    },
    validationSchema: versionSupportValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const { isUpdateRequired, ...rest } = values;
      const res = await new ApiManager().appVersion({
        isUpdateRequired: isUpdateRequired === "1" ? true : false,
        ...rest,
      });
      if (res.success) {
        toast("App Version Changed Successfully", { type: "success" });
        resetForm();
      } else {
        toast(res.error?.error, { type: "error" });
      }
    },
  });

  return (
    <>
      <ChangePasswordForm formik={changePasswordFormik} />
      <VersionSupportForm formik={versionSupportFormik} />
    </>
  );
};

export default AccountSettings;
