import React from "react";

interface PropTypes {
  children: React.ReactNode;
}

const FiltersContainer = ({ children }: PropTypes) => {
  return <div className="sticky top-20 bg-gray-100 z-20">{children}</div>;
};

export default FiltersContainer;
