import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { NotificationType } from "../pages/Notification";
import { capitalize } from "../utils/helpers";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
  notification: NotificationType | null;
}

const ViewNotification = ({ isVisible, onHide, notification }: PropTypes) => {
  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={onHide}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              onClick={onHide}
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block p-4 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="w-full flex justify-end">
                <AiOutlineClose className="cursor-pointer" onClick={onHide} />
              </div>
              <div className="w-full flex flex-col justify-center gap-4 h-full">
                <p>
                  <strong>Title: </strong>
                  {notification?.title || "N/A"}
                </p>
                <p>
                  <strong>Body: </strong>
                  {notification?.body || "N/A"}
                </p>
                <p>
                  <strong>Status: </strong>
                  {notification?.status
                    ? capitalize(notification.status)
                    : "N/A"}
                </p>
                <p>
                  <strong>Status: </strong>
                  {notification?.userType
                    ? capitalize(notification.userType)
                    : "N/A"}
                </p>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewNotification;
