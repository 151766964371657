import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { useFormik } from "formik";
import Input from "./Input";
import * as Yup from "yup";
import { isFileSizeValid } from "../utils/helpers";
import Button from "./Button";
import { twMerge } from "tailwind-merge";
import Textarea from "./Textarea";
import Checkbox from "./Checkbox";
import SpecificTable from "./SpecificTable";
import { instance } from "../utils/ApiManager";
import { toast } from "react-toastify";
import FileInput from "./FileInput";

interface PropTypes {
  isVisible: boolean;
  onHide: () => void;
}

const userTypeOptions = [
  {
    label: "User",
    value: "user",
  },
  {
    label: "Driver",
    value: "driver",
  },
];

const targetOptions = [
  { label: "All", value: "all" },
  { label: "Specific", value: "specific" },
];

const SendNotificationModal = ({ isVisible, onHide }: PropTypes) => {
  const [showSpecificTable, setShowSpecificTable] = useState(false);
  const formik = useFormik<{
    title: string;
    message: string;
    image: string;
    userType: string;
    target: string;
    targets: { id: string; name: string }[];
  }>({
    initialValues: {
      title: "",
      message: "",
      image: "",
      userType: "user",
      target: "all",
      targets: [],
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().required("This field is requuied"),
      message: Yup.string().trim().required("This field is requuied"),
      image: Yup.mixed().test(
        "size",
        "File size must be 1MB or less",
        (value) => {
          if (value && value instanceof File) {
            return isFileSizeValid(value as File);
          }
          return true;
        }
      ),
      userType: Yup.string().required("This field is required"),
      target: Yup.string().required("This field is required"),
      targets: Yup.array().when("target", {
        is: "specific",
        then: (schema) =>
          schema.min(
            1,
            "At least one target must be selected when target is specific"
          ),
        otherwise: (schema) => schema,
      }),
    }),
    onSubmit: async (values) => {
      const body: {
        title: string;
        body: string;
        userType: string;
        specificTargets: boolean;
        targets: string[];
        image?: string;
      } = {
        title: values.title,
        body: values.message,
        userType: values.userType,
        specificTargets: values.target === "specific" ? true : false,
        targets:
          values.target === "specific"
            ? values.targets.map((item) => item.id)
            : [],
      };
      const file = values.image;
      if (file) {
        const fd = new FormData();
        fd.append("file", file);
        const imageRes = await instance.post("/upload", fd);
        if (imageRes.data) {
          body.image = imageRes.data?.Location;
        }
      }
      const res = await instance.post("/admin/notifications", body);
      if (res.status === 200) {
        toast("Notification sent successfully", { type: "success" });
        formik.resetForm();
        onHide();
      }
    },
  });

  const addToTarget = (id: string, name: string) => {
    const arr = [...formik.values.targets];
    arr.push({ id, name });
    formik.setFieldValue("targets", arr);
  };

  const removeFromTarget = (id: string) => {
    const arr = [...formik.values.targets];
    const index = arr.findIndex((item) => item.id === id);
    arr.splice(index, 1);
    formik.setFieldValue("targets", arr);
  };

  useEffect(() => {
    if (formik.values.target === "specific") {
      setShowSpecificTable(true);
    } else if (formik.values.target === "all") {
      formik.setFieldValue("targets", []);
    }
  }, [formik.values.target]);

  return (
    <>
      <Modal
        heading="Send Notification"
        isVisible={isVisible}
        onHide={() => {
          formik.resetForm();
          onHide();
        }}
      >
        <form onSubmit={formik.handleSubmit} className="grid grid-cols-2 gap-4">
          <Input
            label="Title *"
            placeholder="Enter title"
            onChange={formik.handleChange}
            name="title"
            className="input-primary"
            onBlur={formik.handleBlur}
            isInvalid={Boolean(formik.touched.title && formik.errors.title)}
            error={formik.errors.title}
          />
          <FileInput
            label="Image"
            placeholder="Enter image"
            onChange={(e) => {
              if (e.target.files) {
                formik.setFieldValue("image", e.target.files[0]);
              }
            }}
            name="image"
            onBlur={formik.handleBlur}
            isInvalid={Boolean(formik.touched.image && formik.errors.image)}
            error={formik.errors.image}
          />

          <Textarea
            label="Message *"
            placeholder="Enter Message"
            onChange={formik.handleChange}
            name="message"
            className="textarea-primary"
            onBlur={formik.handleBlur}
            isInvalid={Boolean(formik.touched.message && formik.errors.message)}
            error={formik.errors.message}
          />

          <div>
            <div className="label">
              <span className="label-text">Send To</span>
            </div>
            {targetOptions.map((option) => (
              <Checkbox
                key={option.value}
                onChange={(e) =>
                  formik.setFieldValue(
                    "target",
                    e.target.checked ? option.value : ""
                  )
                }
                label={option.label}
                checked={formik.values.target === option.value}
              />
            ))}
            {formik.errors.target || formik.errors.targets ? (
              <div className="label">
                <span className="label-text-alt text-red-600">
                  {formik.errors.target || String(formik.errors.targets)}
                </span>
              </div>
            ) : null}
          </div>
          {formik.values.targets.length > 0 ? (
            <div className="col-span-2 space-y-4">
              <p className="text-lg font-medium">Targets: </p>
              <ol className="pl-4 grid grid-cols-4 gap-2 text-sm list-decimal">
                {formik.values.targets.map((item) => (
                  <li key={item.id}>{item.name}</li>
                ))}
              </ol>
            </div>
          ) : null}
          <div className="col-span-2">
            <div className="join w-full">
              {userTypeOptions.map((option, index) => (
                <Button
                  onClick={() => {
                    formik.setFieldValue("target", "all");
                    formik.setFieldValue("targets", []);
                    formik.setFieldValue("userType", option.value);
                  }}
                  key={option.value + index}
                  className={twMerge(
                    "join-item w-1/2 btn-secondary text-black",
                    formik.values.userType === option.value &&
                      "bg-primary text-white"
                  )}
                  type="button"
                >
                  {option.label}
                </Button>
              ))}
            </div>
            {formik.touched.userType && formik.errors.userType ? (
              <div className="label">
                <span className="label-text-alt text-red-600">
                  {formik.errors.userType}
                </span>
              </div>
            ) : null}
          </div>
          <div className="col-span-2 text-center">
            <Button
              isLoading={formik.isSubmitting}
              type="submit"
              className="px-10"
            >
              Send
            </Button>
          </div>
        </form>
        <SpecificTable
          addToTarget={addToTarget}
          isOpen={showSpecificTable}
          removeFromTarget={removeFromTarget}
          closeModal={() => setShowSpecificTable(false)}
          userType={formik.values.userType}
          targets={formik.values.targets.map((item) => item.id)}
        />
      </Modal>
    </>
  );
};

export default SendNotificationModal;
