import React from "react";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import Button from "./Button";

const Error = () => {
  return (
    <div className="w-full h-screen">
      <TopBar />
      <div className="flex h-full">
        <Sidebar />
        <div className="w-80% gap-6 px-6 py-2 items-center relative flex flex-col justify-center font-inter bg-gray-100 h-full">
          <h1 className="text-2xl font-bold">
            OOPS, Something went wrong. Please try again later.
          </h1>
          <Button onClick={() => window.location.reload()}>Reload Page</Button>
        </div>
      </div>
    </div>
  );
};

export default Error;
