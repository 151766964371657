import { FormikProps } from "formik";
import React from "react";
import Button from "../Button";
import { VersionSupportType } from "../../pages/Settings";

interface PropTypes {
  formik: FormikProps<VersionSupportType>;
}

const VersionSupportForm = ({ formik }: PropTypes) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className="text-xl font-semibold mt-10 mb-6">Version Support</h1>
      <div className="grid grid-cols-4 gap-10">
        <div className="flex flex-col">
          <label htmlFor="latest_version">Latest Version *:</label>
          <input
            id="latest_version"
            name="latestVersion"
            className="w-30% h-14 rounded mt-4 px-4 text-sm focus:outline-none"
            placeholder="0.0.1"
            value={formik.values.latestVersion}
            onChange={formik.handleChange}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="build_number">Build Number *:</label>
          <input
            id="build_number"
            type="number"
            min={0}
            name="buildNumber"
            className="w-30% h-14 rounded mt-4 px-4 text-sm focus:outline-none"
            placeholder="Enter Build Number"
            value={formik.values.buildNumber}
            onChange={formik.handleChange}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="update_required">Update Required *:</label>
          <select
            name="isUpdateRequired"
            onChange={formik.handleChange}
            value={formik.values.isUpdateRequired}
            className="h-14 rounded mt-4 px-4"
            id="update_required"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="user_type">User Type *:</label>
          <select
            value={formik.values.userType}
            className="h-14 rounded mt-4 px-4"
            id="user_type"
            name="userType"
            onChange={formik.handleChange}
          >
            <option value="user">User</option>
            <option value="driver">Driver</option>
          </select>
        </div>
      </div>
      {Object.keys(formik.errors).length > 0 && (
        <div className="text-red-500 mt-4">
          <p>{Object.values(formik.errors)[0]}</p>
        </div>
      )}
      <Button className="mt-4" isLoading={formik.isSubmitting}>
        Save
      </Button>
    </form>
  );
};

export default VersionSupportForm;
