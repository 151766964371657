import React from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ReactPaginate from "react-paginate";

interface PropTypes {
  page: number;
  handlePageClick: (e: { selected: number }) => void;
  currentPage?: number;
}

function Pagination({ page, handlePageClick, currentPage = 1 }: PropTypes) {
  return (
    <div className="w-full justify-center h-full flex items-center">
      <ReactPaginate
        containerClassName="flex items-center"
        pageCount={page}
        forcePage={currentPage - 1}
        previousClassName="pagination-button"
        nextClassName="pagination-button"
        onPageChange={(e) => handlePageClick(e)}
        activeLinkClassName="bg-primary text-white"
        pageClassName="pagination-button"
        pageRangeDisplayed={5}
        marginPagesDisplayed={0}
        nextLabel={<AiOutlineArrowRight />}
        previousLabel={<AiOutlineArrowLeft />}
      />
    </div>
  );
}

export default Pagination;
