import React, { useEffect, useState } from "react";
import { FaInfo } from "react-icons/fa";
import { toast } from "react-toastify";
import AddPromoCodeModal, {
  serviceTypes,
} from "../components/AddPromoCodeModal";
import Button from "../components/Button";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DataListView from "../components/DataListView";
import EditPromoCode from "../components/EditPromoCode";
import Input from "../components/Input";
import Select from "../components/Select";
import Toggle from "../components/Toggle";
import ViewPromoCode from "../components/ViewPromoCode";
import ApiManager from "../utils/ApiManager";
import { capitalize } from "../utils/helpers";
import FiltersContainer from "../components/FiltersContainer";

export type PromoCodeType = {
  createdAt: string;
  currentUsage: number;
  description: string;
  discountType: string;
  discountValue: number;
  expiryDate: null | string;
  maxUsers?: number;
  maxBudget?: number;
  promoCode: string;
  remainingBudget: null | number;
  serviceType: [
    "package_pickup_meta" | "curbside_pickup_meta" | "instore_pickup_meta"
  ];
  startDate: string;
  isNotifyUser?: boolean;
  isPromoCodeActive?: boolean;
  isSingleUse: boolean;
  _id: string;
};

const serviceMap = {
  package_pickup_meta: "Package Pickup",
  curbside_pickup_meta: "Curbside Pickup",
  instore_pickup_meta: "Instore Pickup",
};

export const formatServiceTypes = (
  services: [
    "package_pickup_meta" | "curbside_pickup_meta" | "instore_pickup_meta"
  ]
) => {
  let formattedString = "";
  formattedString = services.map((service) => serviceMap[service]).join(", ");
  return formattedString;
};

const PromoCodes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [promoCodes, setPromoCodes] = useState<PromoCodeType[]>([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [isActive, setIsActive] = useState("");
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    startDate: "",
    expiryDate: "",
    serviceType: "",
    isActive: "",
  });
  const [selectedPromo, setSelectedPromo] = useState<PromoCodeType | null>(
    null
  );
  const [isDeletingPromoCode, setIsDeletingPromoCode] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [promoCodeStatusChangeId, setPromoCodeStatusChangeId] = useState("");
  const [isViewPromoCodeVisible, setIsViewPromoCodeVisible] = useState(false);

  const handleGetAllPromoCodes = async () => {
    setIsLoading(true);
    const { fromDate, toDate, serviceType, isActive } = filters;
    const res = await new ApiManager().getAllPromoCodes({
      page: currentPage,
      startDate: fromDate,
      expiryDate: toDate,
      serviceType,
      isActive,
    });
    if (res.success) {
      setPromoCodes(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetAllPromoCodes();
  }, [
    currentPage,
    filters.fromDate,
    filters.toDate,
    filters.serviceType,
    filters.isActive,
  ]);

  const handleSearch = () => {
    setCurrentPage(1);
    setFilters({
      fromDate,
      toDate,
      startDate,
      expiryDate,
      serviceType,
      isActive,
    });
  };

  const clearFilters = () => {
    setCurrentPage(1);
    setFromDate("");
    setToDate("");
    setStartDate("");
    setExpiryDate("");
    setServiceType("");
    setIsActive("");
    setFilters({
      fromDate: "",
      toDate: "",
      startDate: "",
      expiryDate: "",
      serviceType: "",
      isActive: "",
    });
  };

  const handleDeletePromo = async () => {
    setIsDeletingPromoCode(true);
    const res: any = await new ApiManager().deletePromoCode(
      selectedPromo?._id!
    );
    if (res.success) {
      toast(res.data?.message, { type: "success" });
      setSelectedPromo(null);
      setIsConfirmationModalOpen(false);
      const updatedPromoCodes = promoCodes.filter(
        (promo) => promo._id !== selectedPromo?._id
      );
      if (updatedPromoCodes.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      } else {
        handleGetAllPromoCodes();
      }
    } else {
      toast(res.error?.response?.data?.error, { type: "error" });
    }
    setIsDeletingPromoCode(false);
  };

  const handlePromoCodeActivationChange = async (
    id: string,
    isPromoCodeActive: boolean
  ) => {
    setPromoCodeStatusChangeId(id);
    const res = await new ApiManager().modifyPromoCodeActiveStatus(
      id,
      !isPromoCodeActive
    );
    if (res.success) {
      const arr = [...promoCodes];
      const index = arr.findIndex((item) => item._id === id);
      arr[index].isPromoCodeActive = !isPromoCodeActive;
      setPromoCodes(arr);
    }
    setPromoCodeStatusChangeId("");
  };

  const isAnyFilterApplied = () => {
    return (
      serviceType !== "" || isActive !== "" || fromDate !== "" || toDate !== ""
    );
  };

  const isApplyButtonDisabled = () => {
    return !isAnyFilterApplied();
  };

  const isClearButtonDisabled = () => {
    return (
      fromDate === "" &&
      toDate === "" &&
      startDate === "" &&
      expiryDate === "" &&
      serviceType === "" &&
      isActive === ""
    );
  };

  return (
    <>
      <FiltersContainer>
        <div className="py-4">
          <div className="w-full mb-4 flex items-center justify-end gap-4">
            <Button
              onClick={() => setIsAddModalVisible(true)}
              variant="outline"
            >
              Add Promo Code
            </Button>
          </div>

          <div className="w-full  flex items-center justify-end gap-4 ">
            <Input
              containerClassName="flex flex-row items-center"
              type="date"
              label="Start Date: "
              onChange={(e) => setFromDate(e.target.value)}
              value={fromDate}
            />
            <Input
              containerClassName="flex flex-row items-center"
              type="date"
              label="Expiry Date: "
              onChange={(e) => setToDate(e.target.value)}
              value={toDate}
            />
            <Select
              containerClassName="flex-row"
              options={[{ label: "All", value: "all" }, ...serviceTypes]}
              placeholder="Service Type"
              onChange={(e) => setServiceType(e.target.value)}
              value={serviceType}
            />

            <Select
              containerClassName="flex-row"
              options={[
                { label: "Yes", value: "1" },
                { label: "No", value: "0" },
              ]}
              placeholder="Active"
              onChange={(e) => setIsActive(e.target.value)}
              value={isActive}
            />
            <Button disabled={isApplyButtonDisabled()} onClick={handleSearch}>
              Apply
            </Button>
            <Button
              disabled={isClearButtonDisabled()}
              className="btn-outline border-primary text-primary"
              onClick={clearFilters}
            >
              Clear
            </Button>
          </div>
        </div>
      </FiltersContainer>
      <DataListView
        columns={[
          {
            header: "Promo Code",
            accessor: (item: PromoCodeType) => item.promoCode,
          },
          {
            header: "Discount Type",
            accessor: (item: PromoCodeType) => capitalize(item.discountType),
          },
          {
            header: "Discount Value",
            accessor: (item: PromoCodeType) =>
              item.discountType === "flat"
                ? `$ ${item.discountValue.toFixed(2)}`
                : `${item.discountValue}%`,
          },
          {
            header: "Service Type",
            accessor: (item: PromoCodeType) =>
              formatServiceTypes(item.serviceType),
          },
          {
            header: "Current Usage",
            accessor: (item: PromoCodeType) => item.currentUsage,
          },
          {
            header: "Remaining Budget",
            accessor: (item: PromoCodeType) =>
              item.remainingBudget
                ? `$${(item.remainingBudget / 100).toFixed(2)}`
                : "N/A",
          },

          {
            header: "Actions",
            accessor: (item: PromoCodeType) => (
              <div className="flex items-center">
                <Button
                  onClick={() => {
                    setSelectedPromo(item);
                    setIsViewPromoCodeVisible(true);
                  }}
                  className="btn-outline btn-primary"
                >
                  <FaInfo />
                </Button>
                {item.isPromoCodeActive !== undefined ||
                item.isPromoCodeActive !== null ? (
                  <div className="ml-4">
                    <Toggle
                      labelClassName="mr-2"
                      label="Active"
                      disabled={promoCodeStatusChangeId === item._id}
                      checked={item.isPromoCodeActive === true}
                      onChange={() =>
                        handlePromoCodeActivationChange(
                          item._id,
                          item.isPromoCodeActive!
                        )
                      }
                    />
                  </div>
                ) : // <Button
                //   disabled={promoCodeStatusChangeId === item._id}
                //   onClick={() =>
                //     handlePromoCodeActivationChange(
                //       item._id,
                //       item.isPromoCodeActive!
                //     )
                //   }
                //   className={twMerge(
                //     "ml-4",
                //     item.isPromoCodeActive && "bg-red-600 hover:bg-red-600"
                //   )}
                // >
                //   {item.isPromoCodeActive ? "Deactivate" : "Activate"}
                // </Button>
                null}
                <Button
                  onClick={() => {
                    setSelectedPromo(item);
                    setIsEditModalOpen(true);
                  }}
                  variant="ghost"
                  className="text-primary"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    setSelectedPromo(item);
                    setIsConfirmationModalOpen(true);
                  }}
                  variant="ghost"
                  className="text-red-600"
                >
                  Delete
                </Button>
              </div>
            ),
          },
        ]}
        isFetching={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        emptyStateMessage="No Promo Codes Found"
        data={promoCodes}
        forcePage={currentPage}
      />

      <AddPromoCodeModal
        isVisible={isAddModalVisible}
        onHide={() => setIsAddModalVisible(false)}
        onSuccess={handleGetAllPromoCodes}
      />
      <ConfirmationDialog
        text="Are you sure you want to delete this promo?"
        handleClick={handleDeletePromo}
        isVisible={isConfirmationModalOpen}
        onHide={() => setIsConfirmationModalOpen(false)}
        isSubmitButtonDisabled={isDeletingPromoCode}
        isSubmitting={isDeletingPromoCode}
      />
      <EditPromoCode
        isVisible={isEditModalOpen}
        onHide={() => setIsEditModalOpen(false)}
        promoCode={selectedPromo}
        onSuccess={handleGetAllPromoCodes}
      />
      <ViewPromoCode
        isVisible={isViewPromoCodeVisible}
        onHide={() => {
          setSelectedPromo(null);
          setIsViewPromoCodeVisible(false);
        }}
        promoCode={selectedPromo}
      />
    </>
  );
};

export default PromoCodes;
