import React from "react";
import useVehicleTypes from "../hooks/useVehicleTypes";
import { ApiResponse } from "../pages/SubmissionRequests";
import Modal from "./Modal";

interface PropTypes {
  isVisible: boolean;
  onClose: () => void;
  request: ApiResponse | null;
}

const ViewRequest = ({ isVisible, onClose, request }: PropTypes) => {
  const { getVehicleLabel } = useVehicleTypes();
  return (
    <Modal isVisible={isVisible} onHide={onClose}>
      <div className="w-full flex flex-col justify-center gap-4 h-full">
        <p>
          <span className="font-bold">Driver Name: </span>
          {request?.driver?.fullName || "N/A"}
        </p>
        <p>
          <span className="font-bold">Vehicle Number: </span>
          {request?.driver?.vehicleNumber || "N/A"}
        </p>
        <p>
          <span className="font-bold">Vehicle Type: </span>
          {request?.driver
            ? getVehicleLabel(request?.driver?.vehicleType)
            : "N/A"}
        </p>
        <p>
          <span className="font-bold">Phone: </span>
          {request?.driver?.phone || "N/A"}
        </p>
        <p>
          <span className="font-bold">Driver Email: </span>
          {request?.driver?.email || "N/A"}
        </p>
        <p>
          <span className="font-bold">Reason: </span>
          {request?.reason ? request?.reason : "N/A"}
        </p>
      </div>
    </Modal>
  );
};

export default ViewRequest;
