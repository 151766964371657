import React, { FormEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../Redux/slices/auth";
import { useAppDispatch } from "../Redux/store";
import ApiManager from "../utils/ApiManager";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { getSessionCookie } from "../utils/cookies";

const Login = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isViewingPassword, setIsViewingPassword] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoggingIn(true);
    try {
      const res = await new ApiManager().Login(email, password);
      if (res.success) {
        const user = await new ApiManager().WhoAmI();
        dispatch(login(user.payload?.data));
        navigate("/dashboard");
      } else {
        toast("Invalid Credentials", { type: "error" });
      }
    } catch (error) {
      toast("Invalid Credentials", { type: "error" });
    }
    setIsLoggingIn(false);
  };

  useEffect(() => {
    if (getSessionCookie("token")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Login - Swyvery Admin</title>
      </Helmet>
      <div className="w-screen items-center font-inter bg-gray-100 justify-center flex h-screen">
        <div className="w-[80%] h-[80%] flex">
          <div className="w-1/2 px-10 flex flex-col justify-between rounded-l-xl text-white h-full bg-secondary">
            <div className="w-full mt-20">
              <h2 className="text-3xl font-semibold mb-4">Welcome Back</h2>
              <p className="w-9/12">
                Log in to continue your account and explore new brands & users.
              </p>
            </div>
          </div>
          <div className="w-1/2 rounded-r-xl flex items-center flex-col justify-center h-full bg-white ">
            <h1 className="w-[80%] text-4xl">Login</h1>
            <form
              onSubmit={(e) => handleSubmit(e)}
              className="w-[80%] h-1/2 flex flex-col justify-center"
            >
              <div className="h-20 mb-4 flex flex-col">
                <label htmlFor="email">Email</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                  id="email"
                  className="h-10 outline-none border mt-2 rounded px-4 border-black bg-white"
                  placeholder="example@gmail.com"
                />
              </div>
              <div className="h-20 flex flex-col">
                <label htmlFor="password">Password</label>
                <div className="flex items-center h-10 border mt-2 outline-none rounded px-4 border-black bg-white justify-between">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    id="password"
                    className="outline-none w-full"
                    type={isViewingPassword ? "text" : "password"}
                    placeholder="Enter Password"
                  />
                  {isViewingPassword ? (
                    <IoIosEyeOff
                      className="cursor-pointer"
                      size={24}
                      onClick={() => setIsViewingPassword((prev) => !prev)}
                    />
                  ) : (
                    <IoIosEye
                      className="cursor-pointer"
                      size={24}
                      onClick={() => setIsViewingPassword((prev) => !prev)}
                    />
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <Link to={`/forgot-password?email=${email}`}>
                  <p className="text-primary underline">Forgot Password?</p>
                </Link>
              </div>
              <button
                type="submit"
                className="w-full rounded h-10 mt-8 bg-primary text-white"
              >
                {isLoggingIn ? "Please wait..." : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
