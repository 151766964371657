import React, { ComponentProps, useState } from "react";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { twMerge } from "tailwind-merge";

interface PropTypes extends ComponentProps<"input"> {
  label?: string;
  error?: string;
  isInvalid?: boolean;
  containerClassName?: string;
}

const PasswordInput = ({
  label,
  error,
  className,
  isInvalid = false,
  containerClassName,
  ...rest
}: PropTypes) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={twMerge(containerClassName)}>
      {label ? (
        <div className="label">
          <span className="label-text">{label}</span>
        </div>
      ) : null}

      <div className="relative">
        <input
          {...rest}
          type={showPassword ? "text" : "password"}
          className={twMerge(
            "input input-bordered w-full focus:border-none pr-10",
            className
          )}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? (
            <IoIosEyeOff className="h-5 w-5 text-gray-400" />
          ) : (
            <IoIosEye className="h-5 w-5 text-gray-400" />
          )}
        </button>
      </div>
      {isInvalid ? (
        <div className="label">
          <span className="label-text-alt text-red-600">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default PasswordInput;
